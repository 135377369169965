<template>
<div class="dashboard" v-bind:class="{ 'w100': isClient }">
    <Loader v-if="!eventInfo || !eventInfo.id" />
    <div class="dashboard__container pb-0" v-if="eventInfo && eventInfo.id">
      <div class="dashboard__container--header">
        <div class="flex align-center">
          <span>
          <h1 v-if="eventInfo && eventInfo.title" class="pb-1">{{eventInfo.title}}<span v-if="eventInfo.shiftType">  |  {{eventInfo.shiftType}}</span></h1>
            <span v-if="eventInfo && eventInfo.venue && eventInfo.venue.title">
              <p><span class="strong">Order Number: {{eventInfo.jobNum}} </span><span v-if="eventInfo.refNum"> | {{eventInfo.refNum}}</span> | {{eventInfo.venue.title}}<span v-if="eventInfo.venue && eventInfo.venue.address"> | {{eventInfo.venue.address.city}}, {{eventInfo.venue.address.state}}</span> | {{eventInfo.startDate | moment("ddd, MMM Do YYYY") }}<span v-if="eventInfo.endDate"> - {{eventInfo.endDate | moment("ddd, MMM Do YYYY") }}</span></p>
            </span>
          </span>
        
          <!-- <button class="btn btn__large btn__success ml-5" v-if="event && event.published" :disabled="event.cancelled">
            Published
          </button>
          <button class="btn btn__large btn__warning ml-5" v-if="event && !event.published" :disabled="event.status == 'cancelled'">
            Draft
          </button>
          <button class="btn btn__large btn__danger ml-5" v-if="event && event.status == 'cancelled'">
            Cancelled
          </button> -->
        </div>
        <span class="flex align-center" v-if="!isClient">
          <button v-if="!isWatchList" class="btn btn__outlined btn__small mb-3 mr-3" @click="addWatch()">Watchlist <i class="fa-regular fa-star ml-2"></i></button>
           <button v-if="isWatchList" class="btn btn__outlined btn__small mb-3 mr-3" @click="removeWatch()">Remove <i class="fa-solid fa-star warning ml-2"></i></button>
          <button class="btn btn__outlined btn__small mb-3" @click="goBack"><i class="fas fa-arrow-left"></i></button>
        </span>
        <!-- <button class="btn btn__outlined btn__small mb-3" @click="goBack()"><i class="fas fa-arrow-left"></i></button> -->

      </div>
      <div class="flex align-start flex-wrap mt-2" v-if="!isClient">
        <!-- <router-link :to="`/orders/${$route.params.id}`">
          <button class="btn mr-3 mb-2" v-bind:class="{ 'btn__dark': isEvent, 'btn__outlined': !isEvent }">Order Details</button>
        </router-link> -->
        <router-link :to="`/orders/${$route.params.id}/setup`">
          <button class="btn mr-3 mb-2" v-bind:class="{ 'btn__dark': isSetup, 'btn__outlined': !isSetup }">Order Setup</button>
        </router-link>
        <!-- <router-link :to="`/orders/${$route.params.id}/instructions`">
          <button class="btn mr-3 mb-2" v-bind:class="{ 'btn__dark': isInstructions, 'btn__outlined': !isInstructions }">Staff Instructions</button>
        </router-link>
        <router-link :to="`/orders/${$route.params.id}/jobs`">
          <button class="btn mr-3 mb-2" v-bind:class="{ 'btn__dark': isJobs, 'btn__outlined': !isJobs }">Jobs</button>
        </router-link> -->
        <!-- <router-link :to="`/orders/${$route.params.id}/checkin`">
          <button class="btn mr-3 mb-2" v-bind:class="{ 'btn__dark': isCheckin, 'btn__outlined': !isCheckin }">Check-In</button>
        </router-link> -->
        <!-- <router-link :to="`/orders/${$route.params.id}/shifts`">
          <button class="btn mr-3 mb-2" v-bind:class="{ 'btn__dark': isShifts, 'btn__outlined': !isShifts }">Shifts</button>
        </router-link> -->
        <router-link :to="`/orders/${$route.params.id}/staffing`">
          <button class="btn mr-3 mb-2" v-bind:class="{ 'btn__dark': isStaffing, 'btn__outlined': !isStaffing }">Staffing</button>
        </router-link>
        <!-- <router-link :to="`/orders/${$route.params.id}/placement`">
          <button class="btn mr-3 mb-2" v-bind:class="{ 'btn__dark': isPlacement, 'btn__outlined': !isPlacement }">Placements</button>
        </router-link>
        <router-link :to="`/orders/${$route.params.id}/pipeline`">
          <button class="btn mr-3 mb-2" v-bind:class="{ 'btn__dark': isPipeline, 'btn__outlined': !isPipeline }">Pipeline <i class="fa-regular fa-filter ml-2"></i></button>
        </router-link>
        <router-link :to="`/orders/${$route.params.id}/drops`">
          <button class="btn mr-3 mb-2" v-bind:class="{ 'btn__dark': isDrops, 'btn__outlined': !isDrops }">Drops</button>
        </router-link>
        <router-link :to="`/orders/${$route.params.id}/proximity`">
          <button class="btn mr-3 mb-2" v-bind:class="{ 'btn__dark': isProximity, 'btn__outlined': !isProximity }">Nearby Users</button>
        </router-link> -->
        <router-link :to="`/orders/${$route.params.id}/time/timesheets`">
          <button class="btn mr-3 mb-2" v-bind:class="{ 'btn__dark': isTime, 'btn__outlined': !isTime }">Timekeeping</button>
        </router-link>
        <!-- <router-link :to="`/orders/${$route.params.id}/timesheets`">
          <button class="btn mr-3 mb-2" v-bind:class="{ 'btn__dark': isTimesheets, 'btn__outlined': !isTimesheets }">Timesheets</button>
        </router-link>
        <router-link :to="`/orders/${$route.params.id}/files`">
          <button class="btn mr-3 mb-2" v-bind:class="{ 'btn__dark': isFiles, 'btn__outlined': !isFiles }">Upload Timesheets</button>
        </router-link> -->
        <a :href="`https://jumpstaffing.com/tracker/` + $route.params.id" target="_blank">
          <button class="btn btn__outlined mr-3 mb-2">Tracker <i class="fa-solid fa-up-right-from-square ml-2"></i></button>
        </a>
        <!-- <router-link :to="`/orders/${$route.params.id}/email`">
          <button class="btn mr-3 mb-2" v-bind:class="{ 'btn__dark': isEmail, 'btn__outlined': !isEmail }">Preview Email</button>
        </router-link> -->
        <!-- <a :href="`https://jumpstaffing.com/form/` + $route.params.id" target="_blank">
          <button class="btn btn__outlined mr-3 mb-2">Web Form</button>
        </a> -->
          


      
<!-- 
          <router-link :to="`/users/${$route.params.id}/details`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isDetails, 'btn__outlined btn__small': !isDetails }">Details</button>
          </router-link>
          <router-link :to="`/users/${$route.params.id}/payroll`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isPayroll, 'btn__outlined btn__small': !isPayroll }">Payroll</button>
          </router-link>
          <router-link :to="`/users/${$route.params.id}/assignments`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isAssignments, 'btn__outlined btn__small': !isAssignments }">Assignments</button>
          </router-link>
          <router-link :to="`/users/${$route.params.id}/notes`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isNotes, 'btn__outlined btn__small': !isNotes }">Notes</button>
          </router-link>
          <router-link :to="`/users/${$route.params.id}/reviews`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isReviews, 'btn__outlined btn__small': !isReviews }">Reviews</button>
          </router-link>
          <router-link :to="`/users/${$route.params.id}/gallery`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isGallery, 'btn__outlined btn__small': !isGallery }">Gallery</button>
          </router-link>
          <router-link :to="`/users/${$route.params.id}/messages`">
            <button class="btn btn__small mr-3 mt-3" v-bind:class="{ 'btn__dark': isMessages, 'btn__outlined btn__small': !isMessages }">messages</button>
          </router-link>
 -->
        </div>
    </div>
    <!-- <router-view :key="$route.params.id" :eventInfo="eventInfo" :eventShifts="eventShifts" :eventShiftsDays="eventShiftsDays" /> -->
    <router-view :key="$route.params.id" :eventInfo="eventInfo"  />
  </div>
</template>

<style scoped>
  .w100 {
    width:100%;
  }
</style>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'

export default {
  name: 'eventHome',
  computed: {
    ...mapState(['currentUser', 'userProfile', 'eventInfo']),
    isEvent() {
      return this.$route.name == 'eventDetails';
    },
    isInstructions() {
      return this.$route.name == 'eventInstructions';
    },
    isJobs() {
      return this.$route.name == 'eventJobs';
    },
    isCheckin() {
      return this.$route.name == 'eventCheckin';
    },
    isShifts() {
      return this.$route.name == 'eventShifts';
    },
    isSetup() {
      return (this.$route.name == 'orderHome' || this.$route.name == 'orderDetails' || this.$route.name == 'orderInstructions' || this.$route.name == 'orderJobs' || this.$route.name == 'orderShifts' || this.$route.name == 'orderShift' || this.$route.name == 'addShift')
    },
    isStaffing() {
      return (this.$route.name == 'staffingHome' || this.$route.name == 'staffingPlacements' || this.$route.name == 'staffingProximity' || this.$route.name == 'staffingApplicants' || this.$route.name == 'staffingDrops' || this.$route.name == 'staffingPipeline' || this.$route.name == 'staffingGroups' || this.$route.name == "staffingEmail");
    },
    isPlacement() {
      return this.$route.name == 'eventPlacements';
    },
    isDrops() {
      return this.$route.name == 'eventDrops';
    },
    isTimesheets() {
      return this.$route.name == 'eventTimesheets';
    },
    isTime() {
      return (this.$route.name == 'timeTimesheets' || this.$route.name == 'timeCheckin' || this.$route.name == 'uploadTimesheet');
    },

    isFiles() {
      return this.$route.name == 'eventFiles';
    },
    isEmail() {
      return this.$route.name == 'eventEmail';
    },
    isClient() {
      return this.$route.name == 'eventClient';
    },
    isPipeline() {
      return this.$route.name == 'eventPipeline';
    },
    isProximity() {
      return this.$route.name == 'eventProximity';
    },
    isWatchList() {
      if (this.userProfile && this.userProfile.orderWatchList && this.userProfile.orderWatchList.includes(this.eventInfo.id)) {
        return true
      } else {
        return false
      }
    },
    
    // sheets() {
    //   let url = `/orders/` + this.$route.params.id + `/timesheets`
    //   router.push(url)
    // },
    // shifts() {
    //   let url = `/orders/` + this.$route.params.id + `/shifts`
    //   router.push(url)
    // },
    // placements() {
    //   let url = `/eventplacements/` + this.$route.params.id
    //   router.push(url)
    // },
    // email() {
    //   let url = `/orders/` + this.$route.params.id + `/email`
    //   router.push(url)
    // },
    // checkIn() {
    //   let url = `/orders/` + this.$route.params.id + `/checkin`
    //   router.push(url)
    // },
    // files() {
    //   let url = `/orders/` + this.$route.params.id + `/files`
    //   router.push(url)
    // }
  },
  created () {
    if (!this.eventInfo || (this.eventInfo && !this.eventInfo.id)) {
      console.log("getEventFromId from EventHome")
      this.$store.dispatch("getEventFromId", this.$route.params.id);
    }
  },
  mounted() {
    // if (!this.eventShifts || this.eventShifts.length < 1) {
    //   this.$store.dispatch("getEventShiftsState", this.$route.params.id)
    // }
    // if (!this.eventShiftsDays || this.eventShiftsDays.length < 1) {
    //   this.$store.dispatch("getEventShiftsData", this.$route.params.id)
    // }
    // if (!this.eventGroupUsers || this.eventGroupUsers.length < 1) {
    //   this.$store.dispatch("getEventGroupUsers", this.$route.params.id)
    // }
    // if (!this.eventShifts || this.eventShifts.length < 1) {
    //   this.$store.dispatch("getEventShifts", this.$route.params.id)
    // }
    // if (this.eventInfo && this.eventInfo.id) {
    //   this.$store.dispatch('getEventUsers', {
    //     event: this.eventInfo,
    //   })
    // }
  },
  components: {
    Loader,
  },
  methods: {
    async addWatch() {
      await this.$store.dispatch('watchOrder', this.$route.params.id)
    },
    async removeWatch() {
      await this.$store.dispatch('unWatchOrder', this.$route.params.id)
    },
    goBack() {
      router.go(-1)
    },
    onCopy: function (e) {
      alert('Copied to Clipboard')
    },
  },
  // destroyed() {
  //   console.log('Event Home Destroyed')
  // }
}

</script>